.show {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: fade 1s ease-in-out;
  z-index: 2;
}

.show > div {
  width: 100%;
  height: 100%;
}

@keyframes fade {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

.not-show {
  display: none;
}
